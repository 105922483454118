export default {
  login: 'Log in',
  loginToTeam: 'Log into the {{domain}} team',
  logout: 'Log out',
  signup: 'Sign up',
  signupToTeam: 'Sign up to the {{domain}} team',
  email: 'Email',
  emailAddress: 'Email address',
  workEmailAddress: 'Work email',
  password: 'Password',
  signupSuggestion: 'Or <0>sign up</0> if you don’t have an account',
  loginSuggestion: 'Already have an account? <0>Sign in</0>',
  createAccountSuggestion: 'Or <0>create account</0> if you don’t have one already',
  signupInstructions: 'We store and process your data in accordance with our <0>Privacy Policy</0>. You can revoke consent anytime by contacting our <1>support team</1>. You can also unsubscribe from optional emails anytime.',
  signupNewsletters: 'I want to receive emails with special offers and news from {{company}} (optional).',
  signupPrivacyPolicy: 'I confirm that I have read and accept the terms and conditions in the <0>Customer Agreement</0>. <1>*</1>',
  signupDataProcess: 'I agree to allow {{company}} to store and process the data I have provided in order to create and administer my account and to provide the products and services I have requested. <0>*</0>',
  signupFinishedTitle: 'Check your inbox',
  signupFinishedMessage: 'We need you to click on the verification link that we’ve sent to your email to complete your signup process.',
  signupNote: 'Note: You will receive a verification email to complete the sign up process.',
  returnToLogin: 'Return to log in',
  sendResetLink: 'Send me a reset link',
  forgotPassword: 'Forgot password?',
  forgotPasswordTitle: 'Forgot your password?',
  resetPasswordTitle: 'Reset your password',
  resetPasswordInstructions: 'Enter the email address you used to log in. We’ll send you a password reset link.',
  resetPasswordEmailSent: 'Password recovery email sent',
  resetPasswordEmailSentMessage: 'We’ve sent an email to {{email}} with password reset instructions.',
  resetPassword: 'Reset password',
  passwordUpdated: 'Your password has been updated!',
  restorePasswordLinkInvalid: 'Your restore password link is invalid or expired...',
  newPassword: 'New Password',
  confirmNewPassword: 'Confirm New Password',
  passwordsDontMatch: 'Passwords don’t match.',
  backToMyAccount: 'Back to <0>my account</0>',
  backToLogin: 'Back to <0>log in</0>',
  yourPassword: 'Your Password',
  verifyEmail: 'Verify email',
  verifyEmailTitle: 'Verify your email',
  verifyEmailInstructions: 'Please enter your password to verify your email address.',
  resendVerifyEmail: 'If you didn’t receive it, please <0>click here</0> to try again.',
  verificationEmailResentMessage: 'We sent you an email with a verification link. Please click on it so that we can finish signing you up.',
  startIntegrating: 'Start integrating',
  chooseSubdomain: 'Choose subdomain',
  chooseSubdomainTitle: 'Choose your subdomain',
  chooseSubdomainInstructions: [
    'We have successfully verified your email.',
    'Now we just need to choose a subdomain for your Team. $t(chooseSubdomainExample)'
  ],
  createTeamInstructions: [
    'You have no teams now.',
    'If you wish to create one, please choose a subdomain. $t(chooseSubdomainExample)'
  ],
  chooseSubdomainExample: 'Your subdomain will be used to create your sessions links. e.g., {{domain}}/session.',
  subdomain: 'Subdomain',
  onboardingFinished: 'You’re all set!',
  onboardingFinishedMessage: 'Thank you for completing your signup. You’re all set to start integrating videoconferences into your services.',
  myAccount: 'My account',
  statistics: 'Statistics',
  team: 'Team',
  rooms: 'Rooms',
  activeRooms: 'Active rooms',
  sessions: 'Sessions',
  createRoom: 'Create room',
  createNewRoom: 'Create new room',
  updateRoom: 'Update room',
  sessionsInRoom: 'Sessions in this room',
  createRoomTitle: 'Create a new room',
  cancel: 'Cancel',
  update: 'Update',
  join: 'Join',
  edit: 'Edit',
  back: 'Back',
  editRoom: 'Edit room',
  deleteRoom: 'Delete Room',
  deleteRoomTitle: 'Delete room "{{room}}"?',
  deleteRoomConfirmation: 'Are you sure you want to delete the "{{room}}" room? Any live sessions and session tokens created in this room will stop working; you won’t be able to restore this room or undo this action.',
  name: 'Name',
  room: 'Room',
  roomId: 'Room ID',
  roomName: 'Room name',
  roomNamePlaceholder: 'Will be autogenerated if left empty',
  status: 'Status',
  copy: 'Copy',
  description: 'Description',
  role: 'Role',
  privacy: 'Privacy',
  enablePrivacy: 'Make this room private',
  enablePrivacyDescription: 'In public rooms, participants can join using a simple room link that only includes the room name, without needing any additional token. However, in private rooms, participants will require a session token to join, so the link provided will need to include the necessary token for them to access the room.',
  public: 'Public',
  private: 'Private',
  maximumParticipants: 'Maximum participants',
  maximumParticipantsDescription: 'Number of maximum allowed concurrent participants per room. Your {{plan}} Plan is limited to {{limit}}.',
  maximumParticipantsE2eeDescription: 'Number of maximum allowed concurrent participants per room. Your {{plan}} Plan is limited to {{limit}}, but you will not be able to set it to more than {{e2eeLimit}} in end-to-end encrypted sessions.',
  roomSettings: 'Room settings',
  roomSettingsDescription: 'Room settings except Privacy, Maximum participants and Maximum broadcasters are inherited from <0>default room settings</0>. You may customise your room by overriding these defaults; only unedited properties will continue to inherit future edits to default room settings.',
  revertRoomSettings: 'Reset to default',
  browser: 'Browser',
  duration: 'Duration',
  created: 'Created ($t(timezone))',
  leftTime: 'Left ($t(timezone))',
  joined: 'Joined ($t(timezone))',
  started: 'Started ($t(timezone))',
  endedTime: 'Ended ($t(timezone))',
  upcomingDeletion: 'Upcoming deletion ($t(timezone))',
  lastActive: 'Last active ($t(timezone))',
  timezone: 'UTC',
  elapsed: 'Elapsed',
  system: 'System',
  participantDetails: 'Participant details',
  participantLog: 'Participant log',
  roomsNotFound: 'You have not created any rooms yet.',
  roomsNotFoundBySearchCriteria: 'No rooms found using your search criteria.',
  participantLogsNotFound: 'There are no any participants logs.',
  teamsNotFound: 'There are no matching teams.',
  usersNotFound: 'There are no any users.',
  accountsNotFound: 'There are no any accounts.',
  librariesNotFound: 'There are no any libraries.',
  participantsNotFound: 'There are no participants in this session yet.',
  liveSessionsNotFound: 'There are no live sessions now. To start a new session, you will need to join a room.',
  archiveSessionsNotFound: 'There are no sessions ended yet. To start a new session, you will need to join a room.',
  sessionsNotFound: 'No sessions found using your search criteria. To start a new session, you will need to join a room.',
  recordingsNotFound: 'You have not created any recordings yet.',
  recordingsNotFoundBySearchCriteria: 'No recordings found using your search criteria.',
  contentNotFoundBySearchCriteria: 'No data found using your search criteria.',
  recordingsInRoomNotFound: 'You have not created any recordings in `{{ room }}` room yet.',
  archivedRecordingsNotFound: 'There are no any archived recordings.',
  rolesNotFound: 'There are no any roles yet.',
  surveyResultsNotFound: 'There are no any satisfaction surveys results.',
  showMore: 'Show more',
  showLess: 'Show less',
  session: 'Session',
  sessionId: 'Session ID',
  liveSessions: 'Live sessions',
  endedSessions: 'Ended sessions',
  sessionDetails: 'Session details',
  id: 'ID',
  participant: 'Participant',
  participantId: 'Participant ID',
  participantsTitle: 'Participants',
  participantsNow: 'Participants (now)',
  participantsMax: 'Participants (max)',
  participantsTotal: 'Participants (total unique)',
  device: 'Device',
  seeRooms: 'See rooms',
  joinTeam: 'Join',
  joinTeamTitle: 'Join to the {{domain}} team',
  myTeams: 'My teams',
  emailUpdatedMessage: 'We’ve sent an email to <strong>{{new_email}}</strong>. Please, open it and verify your new email. Your old email <strong>{{email}}</strong> will be active until you verify the new one.',
  leave: 'Leave',
  leaveTeam: 'Leave team',
  leaveTeamTitle: 'Leave team?',
  leaveTeamConfirmation: 'Are you sure you want to leave the {{domain}} team? Once you leave, if you want to join again, you will need a new invitation from the Team Admin.',
  developerKey: 'Developer key',
  copyKey: 'Copy key',
  copyId: 'Copy ID',
  copyLink: 'Copy link',
  teamId: 'Team ID',
  getDeveloperKeyMessage: 'Contact your team administrator for accessing developer key.',
  teamDetailsInstructions: [
    'These values are used to authenticate to our API.',
    'You can find more information on our <0>API documentation</0>'
  ],
  addMembers: 'Add members',
  addMembersTitle: 'Add members to your team',
  addMembersInstructions: [
    'Share this invite link with anyone you wish to add to your team.',
    'It will expire in 72 hours.',
    'We shared an invite link for you to share with anyone you wish to add to your team. It will expire in {{hours}} hours. If you lost it, you may always regenerate it.'
  ],
  regenerate: 'Regenerate',
  regenerateDeveloperKey: 'Regenerate',
  regenerateDeveloperKeyTitle: 'Regenerate developer key?',
  regenerateDeveloperKeyInstructions: [
    'Are you sure you want to regenerate the API key?',
    'REST API calls using your current key will stop working.',
    'Tokens that are already issued with the current key will no longer be valid.',
    'You won’t be able to recover your current key or undo this action.'
  ],
  teamSettings: 'Default room settings',
  teamSettingsDescription: 'New rooms will be moulded out of these default room settings. Any changes applied to these default room settings will be inherited by non-customised settings in existing rooms. However, already customised settings in existing rooms will not be overridden by changes to these default settings.',
  demote: 'Demote',
  promote: 'Promote',
  remove: 'Remove',
  delete: 'Delete',
  demoteMemberTitle: 'Demote to Team Member?',
  promoteMemberTitle: 'Promote to Team Admin?',
  removeMemberTitle: 'Remove team member?',
  demoteMemberConfirmation: 'Are you sure you want to demote {{email}} to Team Member?',
  promoteMemberConfirmation: 'Are you sure you want to promote {{email}} to team admin?',
  removeMemberConfirmation: 'Are you sure you want to permanently remove {{email}}',
  joinRoomTitle: 'Join "{{room}}" Room',
  dontShowAgain: 'Don’t show again',
  language: 'Default language',
  languageDescription: 'Select a default language for the room’s UI.',
  languages: {
    en: 'English',
    'es-ES': 'Español',
    'de-DE': 'Deutsch (Persönlich)',
    df: 'Deutsch (Förmlich)',
    'zh-CN': '中文简体',
    'zh-TW': '中文繁體',
    deutsch: 'Deutsch'
  },
  languageSelection: 'Enable language selection',
  languageSelectionDescription: 'Allow each user to control application UI language on their side.',
  e2eEncryption: 'End-to-end encryption',
  e2eEncryptionDescription: 'Secure all video and audio streams, including shared screens, with encryption to ensure that the data is only accessible to the intended recipients and cannot be read or modified by unauthorised users. Usernames and chat messages will also be end-to-end encrypted. Breakout rooms, Q&A, Transcription and Polls do not support E2EE and will be disabled. Recordings and Whiteboard also do not support E2EE. E2EE will be suspended while recording. Whiteboard content will be transient when on end-to-end encrypted sessions.',
  comingSoon: 'Coming soon',
  beta: 'Beta',
  joinScreen: 'Join screen',
  joinScreenDescription: 'When enabled, users will have the chance to test their speakers, camera and microphone ahead of joining.',
  microphonesOnJoin: 'Microphones on join',
  microphonesOnJoinDescription: 'When disabled, users will join the session with muted microphones. You may want to disable this feature to prevent unwanted incoming noise, especially in large rooms. Users will, however, always have control to unmute and mute their mics.',
  camerasOnJoin: 'Cameras on join',
  camerasOnJoinDescription: 'When disabled, users will join the session with turned-off cameras. Users will, however, always have control over the state of their cameras.',
  logoEnabled: 'Show logo in room',
  logoEnabledDescription: 'When enabled, the above room logo will be shown in the rooms, by default.',
  logoEnabledRoomDescription: 'When enabled, the above room logo will be shown in this room.',
  logoEnabledDescriptionFreePlan: 'When enabled, the above room logo will be shown in the rooms. <0>Upgrade your account</0> to gain the option to disable this feature.',
  logoEnabledRoomDescriptionFreePlan: 'When enabled, the above room logo will be shown in this room. <0>Upgrade your account</0> to gain the option to disable this feature.',
  primaryColour: 'Primary colour',
  primaryColourDescription: 'Select a colour for buttons and other interactive elements.',
  primaryColourRoomDescription: 'Select a colour for buttons and other interactive elements in this room.',
  toolbarColour: 'Toolbar colour',
  toolbarColourDescription: 'Select a background colour for the toolbar.',
  toolbarColourRoomDescription: 'Select a background colour for the toolbar in this room.',
  toolbarPosition: 'Toolbar position',
  toolbarPositionDescription: 'Choose your desired position for the toolbar. Mobile screens will dynamically position the toolbar on the shorter side of the screen according to orientation.',
  left: 'Left',
  bottom: 'Bottom',
  right: 'Right',
  hidden: 'Hidden',
  mobileUsersScreenshareNote: 'Mobile users will not be able to share their screen.',
  screenshare: 'Screenshare',
  screenshareDescription: 'Allow your participants to share their screen. $t(mobileUsersScreenshareNote)',
  participantsList: 'Participants panel',
  participantsListDescription: 'When enabled, your participants will have access to the Participants panel.',
  pinning: 'Pinning',
  pinningDescription: 'When enabled, participants will have the option to select a participant to enlarge and continuously watch. Pinning selections are personal, other participants will not see who you pinned. Will not work in Auto mode.',
  fullScreen: 'Full screen',
  fullScreenDescription: 'Allow your participants to expand any participant tile to full screen. Will not work in Auto mode.',
  minimiseOwnTile: 'Minimise own tile',
  minimiseOwnTileDescription: 'Allow your participants to minimise / maximise their own tile, so that they may use their screen space smartly to give more room to other items. Will not work in Auto mode.',
  endSession: 'End session',
  endSessionDescription: 'When enabled, the "End session" button will be available in the toolbar.',
  notifyJoinAndLeave: '\'Join\' and \'leave\' notifications',
  notifyJoinAndLeaveDescription: 'Send participants non-disruptive notifications to inform them when others join and leave the session.',
  copyrights: '© 2002-{{year}} Digital Samba, SL',
  pageNotFoundTitle: '404 - Page not found!',
  pageNotFoundDescription: 'The following problems could be the case',
  pageNotFoundReasons: [
    'The link has expired',
    'The link is broken',
    'This file never existed',
    'This file has been removed'
  ],
  seconds_one: '{{count, number}} sec',
  seconds_other: '{{count, number}} sec',
  minutes_one: '{{count, number}} min',
  minutes_other: '{{count, number}} mins',
  hours_one: '{{count, number}} hour',
  hours_other: '{{count, number}} hours',
  hours_and_minutes_one: '{{hours}} h {{count, number}} min',
  hours_and_minutes_other: '{{hours}} h {{count, number}} mins',
  messages: {
    revertRoomSettingSuccess: 'Reverted successfully. Press "Update room" if you wish to save the reverted settings.',
    updateAccountSuccess: 'Your account has been updated successfully.',
    deleteAccountSuccess: 'Your account has been deleted successfully.',
    updateTeamSuccess: 'Default room settings have been updated successfully.',
    updateTeamFail: 'Can not update default room settings.',
    setCurrentTeamFail: 'Can not change your current team.',
    joinTeamSuccess: 'You have been joined to the team.',
    updateDeveloperKeySuccess: 'Developer key has been updated successfully.',
    removeMemberSuccess: 'Member has been removed.',
    updateMemberRoleSuccess: 'Member role has been updated successfully.',
    leaveTeamSuccess: 'You left the team.',
    deleteRoomSuccess: 'Room has been deleted.',
    deleteRoomFail: 'Can not delete room.',
    createRoomSuccess: 'Your room has been created successfully.',
    meetNowSuccess: 'New room has been created.',
    updateRoomSuccess: 'Your room has been updated successfully.',
    cloneRoomSuccess: 'Your room has been cloned successfully.',
    cloneRoleSuccess: 'Your role has been cloned successfully.',
    deleteRecordingSuccess: 'Recording has been deleted.',
    deleteRecordingFail: 'Can not delete recording.',
    archiveRecordingSuccess: 'Recording has been archived.',
    archiveRecordingFail: 'Can not archive recording.',
    restoreRecordingSuccess: 'Recording has been unarchived.',
    restoreRecordingFail: 'Can not unarchive recording.',
    deleteWebhookSuccess: 'Webhook has been deleted.',
    deleteWebhookFail: 'Can not delete webhook.',
    createWebhookSuccess: 'Your webhook has been created successfully.',
    updateWebhookSuccess: 'Your webhook has been updated successfully.',
    deleteRoleSuccess: 'Role has been deleted.',
    deleteRoleFail: 'Can not delete role.',
    createRoleSuccess: 'Your role has been created successfully.',
    updateRoleSuccess: 'Your role has been updated successfully.',
    sendVerificationEmailSuccess: 'Verification email has been resent.',
    teamUpdated: 'Team has been updated.',
    unknownError: 'Unknown error...',
    serverUnavailable: 'Server unavailable...',
    selectMoreThenOneRole: 'You must add more than 1 role, or none at all.',
    fieldIsRequired: 'The $t({{name}}) field is required.',
    invitationLinkInvalid: 'Invitation link is invalid or expired...',
    copySuccess: '{{ name }} was successfully copied!',
    copyLinkSuccess: '$t(messages.copySuccess, { "name": "Link" })',
    updateRecordingSuccess: 'Your recording has been updated successfully.',
    imageMaxWidthExceeded: 'Can\'t upload image. Max width exceeded, must be <={{width}}px.',
    imageMaxHeightExceeded: 'Can\'t upload image. Max height exceeded, must be <= {{height}}px.',
    imageFileSizeExceeded: 'Can\'t upload image, file size is exceeded, must be <= {{size}}Mb',
    imageFileTypeInvalid: 'Can\'t upload image, this file type is not allowed.',
    updateTeamDomainSuccess: 'Team subdomain has been updated successfully.',
    updateTeamCnameSuccess: 'Custom domain has been updated successfully.',
    createUserSuccess: 'User has been added successfully.',
    updateUserSuccess: 'User has been updated successfully.',
    deleteUserSuccess: 'User has been deleted successfully.',
    endSessionSuccess: 'The session has been successfully ended.',
    changeOwnerSuccess: 'The team ownership has been transferred successfully.',
    sessionSummaryInProgress: 'Session summary in progress, please try again in a few minutes.',
    sessionSummaryFailed: 'Something went wrong, we can\'t get summary for this session...',
    downgradeRequesSuccessful: 'Downgrade request successful',
    twoFactorAuthEnabled: 'Two factor authentication is now activated on your account.',
    createLibrarySuccess: 'Your library has been created successfully.',
    updateLibrarySuccess: 'Your library has been updated successfully.',
    deleteLibrarySuccess: 'Library has been deleted.',
    createFolderSuccess: 'Your folder has been created successfully.',
    updateFolderSuccess: 'Your folder has been updated successfully.',
    deleteFolderSuccess: 'Folder has been deleted.',
    createFileSuccess: 'Your file has been added successfully.',
    uploadFilesSuccess: 'Your files have been uploaded successfully.',
    updateFileSuccess: 'Your file has been updated successfully.',
    deleteFileSuccess: 'File has been deleted.',
    cleanupArchiveSuccess: 'Archived recordings have been deleted.',
    cleanupArchiveFail: 'Can\'t delete recordings.'
  },
  activeParticipants: 'Active participants',
  liveParticipants: 'Live participants',
  participationMinutes: 'Participation minutes',
  broadcastedMinutes: 'Broadcasted minutes',
  consumedMinutes: 'Consumed minutes',
  currentPeriod: 'This period, <0>{{start}} - {{end}}</0> (UTC)',
  realTime: 'Real time',
  thisPeriod: 'This period, {{start}} - {{end}} (UTC)',
  customPeriod: 'Custom period',
  thisMonth: 'This month',
  lastMonth: 'Last month',
  last30Days: 'Last 30 days',
  last7Days: 'Last 7 days',
  thisWeek: 'This week',
  lastWeek: 'Last week',
  yesterday: 'Yesterday',
  today: 'Today',
  activeUsers: 'Active users',
  activeParticipantsTooltip: 'Number of unique users that have participated at least once in at least one session',
  liveParticipantsTooltip: 'Number of users currently active in a session',
  participationMinutesTooltip: 'Number of minutes a participant has spent in a session from the moment they join until they leave or the session ends',
  liveSessionsTooltip: 'Number of sessions currently running',
  sessionsTooltip: 'Number of sessions created',
  roomsTooltip: 'Number of rooms used',
  broadcastedMinutesTooltip: 'Total video and audio + video only + audio only + screenshare broadcasted minutes',
  consumedMinutesTooltip: 'Total video and audio + video only + audio only + screenshare consumed minutes',
  breakoutMinutesTooltip: 'Number of minutes in open breakouts',
  qaPostsTooltip: 'Total posted questions and answers',
  upgradeToEnable: '<0>Upgrade your plan</0> to unlock this feature.',
  upgradeToIncrease: 'Consider <0>upgrading</0>, if you need more.',
  upgradeToUnlimitRecordings: '<0>Upgrade your plan</0> to unlock limitless recording.',
  enableRecordings: 'Recordings',
  enableRecordingsDescription: 'Allow participants to record sessions. Remember recordings are not compatible with E2EE.',
  enableRecordingsDescriptionFreePlan: 'Allow participants to record sessions. Recordings are limited in the Free plan: Recording length are limited to 15 mins; Total recorded mins are limited 60 at all; Storage is limited to 1 month per recording, after which the recording will be deleted.',
  recordings: 'Recordings',
  qaPosts: 'Q&A posts',
  author: 'Author',
  title: 'Title',
  archive: 'Archive',
  archiveRecording: 'Archive Recording',
  archiveRecordingTitle: 'Archive recording?',
  archiveRecordingConfirmation: '"{{recording}}" will be archived. Archived recordings are automatically deleted after 30 days. You will not be charged for storage cost during this period. Learn more on our <0>Privacy Policy</0>.',
  enableChat: 'Chat',
  enableChatDescription: 'Allow your participants to post messages on a general chat.',
  admin: 'Admin',
  adminExit: 'Exit Admin',
  allTeams: 'All teams',
  domain: 'Domain',
  domainPlaceholder: 'Domain (eg. conference.yourdomain.com)',
  plan: 'Plan',
  global: 'Global',
  backToAdminDashboard: 'Back to Super Admin dashboard',
  backgroundColour: 'Background colour',
  backgroundColourDescription: 'Select a colour for the background.',
  backgroundColourRoomDescription: 'Select a colour for the background in this room.',
  panelsBackgroundColourDescription: 'Select a colour for the background of the panels.',
  download: 'Download',
  play: 'Play',
  documentation: 'Documentation',
  restApiDescription: '<0>Developer REST API</0>: Manage your rooms and team with easy to use REST API. Targeted for backend usage - never leak your team developer key in client-facing code.',
  embeddedSdkDescription: '<0>Embedded SDK</0>: Build video experiences into your browser with few lines of JavaScript code.',
  dashboardDocsDescription: '<0>Dashboard</0>: Set up your team, configure your rooms, add moderation, troubleshoot sessions, get usage data and manage your billing using our GUI.',
  toolbarVisibility: 'Toolbar',
  toolbarVisibilityDescription: 'When disabled, participants will not see the toolbar. You will need to use the <0>SDK</0> to access the toolbar functionalities from within your website or app.',
  recordingLogoEnabled: 'Show logo in recordings',
  recordingLogoEnabledDescription: 'When enabled, the logo will be shown in recordings, by default.',
  recordingLogoEnabledRoomDescription: 'When enabled, the logo will be shown in this room’s recordings.',
  raiseHand: 'Raise hand',
  raiseHandDescription: 'Allow participants to raise hand to take turns to speak without disrupting sessions.',
  raiseHandRoomDescription: 'Allow participants to raise hand to take turns to speak without disrupting this session.',
  setupAccountTitle: 'Hello {{name}}, please setup your password',
  setupAccountLinkInvalid: 'Your setup account link is invalid or expired...',
  setupPasswordTitle: 'Set up your password',
  setupPassword: 'Set up your password',
  confirmPassword: 'Verify your password',
  send: 'Send',
  paletteMode: 'Appearance',
  paletteModeDescription: 'Appearance will control the background colour of panels, modals and join screen.',
  light: 'Light',
  dark: 'Dark',
  contractedLimit: 'Contracted limit',
  contractedLimitTooltip: 'Contracted limit',
  recordedMinutes: 'Recorded minutes',
  recordedMinutesTooltip: 'Number of recorded minutes',
  recordedMinutesThisPeriodTooltip: 'Number of recorded minutes this period',
  recordingStorageMinutes: 'Recording storage',
  recordingStorageMinutesTooltip: 'Number of recorded minutes stored',
  member: 'Member',
  owner: 'Owner',
  activeRecordings: 'Active recordings',
  activeRecordingsTooltip: 'Number of sessions being recorded just now',
  maxActiveRecordings: 'Max concurrent recs.',
  maxActiveRecordingsTooltip: 'Simultaneous recordings peaks registered by day',
  signupDate: 'Signup date',
  topbarVisibility: 'Top bar',
  topbarVisibilityDescription: 'When disabled, participants will not see the top bar or any of the elements or UI contained within: Any logo, recording label, E2EE label, Picture-in-picture, layout mode switcher. This setting affects only the room UI and will have no effect on recordings.',
  modeSwitcher: 'Layout mode switcher',
  modeSwitcherDescription: 'When disabled, participants will not see the layout mode switcher. Sessions will permanently be set in the layout mode selected in the ‘Layout Mode on join’ setting.',
  modeSwitcherRoomDescription: 'When disabled, participants will not see the layout mode switcher. This session will permanently be set in the layout mode selected in the ‘Layout Mode on join’ setting.',
  inProgress: 'Recording',
  pendingConversion: 'Processing',
  enableCaptions: 'Captions',
  enableCaptionsDescription: 'Participants will have the option to enable or disable captions on their end.',
  minimiseOwnTileOnJoin: 'Minimise own tile on join',
  minimiseOwnTileOnJoinDescription: 'When enabled, participants will join the room with their own tile minimised.',
  upgradePlan: 'Upgrade plan',
  manageBilling: 'Manage billing',
  manageBillingDescription: 'Upgrade / downgrade account, update billing info',
  captionsMode: 'Captions mode',
  captionsModeDescription: 'The live-speech mode corrects captions on the fly for a more synchronised feel. The end-of-speech mode waits until the current speaker finishes their sentence before displaying the transcribed sentence, providing a less hectic experience.',
  endOfSpeech: 'End of speech',
  liveSpeech: 'Live speech',
  webhooks: 'Webhooks',
  webhooksDescription: 'Webhooks expose Digital Samba events allowing you to set custom callbacks to invoke behaviour on your app. Supported events: ‘session started’, ‘session ended’, ‘participant joined’, ‘participant left’, ‘recording started’, ‘recording stopped’, ‘recording ready for download’.',
  createWebhook: 'Add webhook',
  createWebhookDescription: 'Digital Samba Embedded will make an HTTP request to the URL configured for your webhook.',
  webhooksNotFound: 'You have not created any webhooks yet.',
  createWebhookTitle: 'Add webhook',
  updateWebhook: 'Update webhook',
  deleteWebhook: 'Delete webhook',
  deleteWebhookTitle: 'Delete webhook?',
  deleteWebhookConfirmation: 'Are you sure you want to delete this webhook? You won’t be able to undo this action.',
  url: 'URL',
  authorisationHeader: 'Authorisation header',
  events: 'Events',
  participantJoined: 'Participant joined',
  participantLeft: 'Participant left',
  cancelledExpiresOn: 'Cancelled - expires on {{date}}.',
  virtualBackgrounds: 'Virtual backgrounds',
  virtualBackgroundsDescription: 'When enabled, participants will be able to appear on virtual backgrounds. The feature is only available for desktop users. Blurred background is not available in Safari.',
  continue: 'Continue',
  roles: 'Roles',
  roleName: 'Role identifier',
  roleNameDescription: 'This identifier needs to be unique across all roles in your team. You will use it inside the JWT token to assign a role to each participant.',
  roleDisplayName: 'Role display name',
  roleDisplayNameDescription: 'This is how the role will be displayed in the room. Note that preset display names will be translated to our supported languages, however any custom values will remain monolingual.',
  roleDescription: 'Role description',
  rolesDescription: 'You will be able to choose any subset of roles when you create your rooms.<br /> You may also create additional custom roles, from scratch or by cloning an existing role.',
  createRole: 'Create role',
  createNewRole: 'Create new role',
  updateRoleTitle: 'Edit role',
  createRoleTitle: 'Create a new role',
  createRoleDescription: 'Please review your existing roles and consider adding this new role to their permissions. Permissions marked with an \'All roles\' label will automatically add new custom roles created in the future.',
  updateRole: 'Update role',
  deleteRole: 'Delete role',
  attendees: 'Attendees',
  audience: 'Audience',
  auditors: 'Auditors',
  hosts: 'Hosts',
  listeners: 'Listeners',
  moderators: 'Moderators',
  observers: 'Observers',
  participants: 'Participants',
  presenters: 'Presenters',
  speakers: 'Speakers',
  students: 'Students',
  support: 'Support',
  teachers: 'Teachers',
  techSupport: 'Tech. support',
  viewers: 'Viewers',
  custom: 'Custom',
  enterRoleName: 'Enter role name',
  permissions: {
    broadcast: 'Broadcast',
    broadcastDescription: 'Will have the ability to use their camera and microphone to broadcast.',
    manageBroadcast: 'Manage broadcast permission',
    manageBroadcastDescription: 'Will have the ability to give and remove broadcast permission and lower raised hands of the selected roles.',
    endSession: 'End session',
    endSessionDescription: 'Will have the ability to end the session for everyone.',
    startSession: 'Start session',
    startSessionDescription: 'When enabled, participants will be able to start sessions. They will always be able to seamlessly join sessions into locked moderated rooms, without the need to be expressly accepted. However in locked single-role rooms they need an existing participant\'s acceptance.',
    removeParticipant: 'Remove participants',
    removeParticipantDescription: 'Will have the ability to remove participants with selected roles from the session.',
    screenshare: 'Screenshare',
    screenshareDescription: 'Will have the ability to share their screen\'s video and audio. $t(mobileUsersScreenshareNote)',
    manageScreenshare: 'Manage screenshare',
    manageScreenshareDescription: 'Will have the ability to give and remove screenshare permission to selected roles. $t(mobileUsersScreenshareNote)',
    recording: 'Recording',
    recordingDescription: 'Will have the ability to start and stop recordings.',
    generalChat: 'Chat in public chat',
    generalChatDescription: 'Will have the ability to chat openly in the public chat.',
    remoteMuting: 'Remote turn off cam / mic',
    remoteMutingDescription: 'Will have the ability to remotely mute or turn off camera of participants with selected roles. Choose ‘All roles’ if you want the roles you create after submitting this form to be automatically considered as selected roles for this permission. Note the ‘Mute all’ button will only be available if targeting all roles.',
    askRemoteUnmute: 'Remote ask to turn on cam / mic',
    askRemoteUnmuteDescription: 'Will have the ability to remotely ask selected roles to unmute or turn on camera.',
    raiseHand: 'Raise hand',
    raiseHandDescription: 'Will have the ability to raise their hand to request broadcast permissions or take turns to speak.',
    manageRoles: 'Manage role',
    manageRolesDescription: 'Will have the ability to move participants between the selected roles.',
    inviteParticipant: 'Invite participants',
    inviteParticipantDescription: 'Will have the ability to invite participants into the session.',
    seeParticipantsPanel: 'See participants panel',
    seeParticipantsPanelDescription: 'Will have the ability to see the participants panel and the ‘join’ and ‘leave’ notifications.',
    controlRoomEntry: 'Control room entry',
    controlRoomEntryDescription: 'In multi-role rooms, those with ‘control room entry’ permission can accept or reject participants joining locked sessions. This permission enables seamless entry into existing sessions in locked multi-role rooms. In single-role rooms, this permission doesn\'t apply as entry is managed by existing participants.',
    editWhiteboard: 'Edit whiteboard',
    editWhiteboardDescription: 'Will have the ability to open and close the whiteboard, and add and edit content with the tools provided in it.',
    manageEditWhiteboard: 'Manage edit-whiteboard permission',
    manageEditWhiteboardDescription: 'Will have the ability to give and remove edit-whiteboard permission to selected roles.',
    manageBreakout: 'Manage breakouts',
    manageBreakoutDescription: 'Will have the ability to create breakout rooms, assign participants, start and stop breakouts and move around breakout rooms. Participants with this permission will not be assigned to any breakout room when creating rooms automatically, unless in single-role rooms.',
    answerQa: 'Answer questions in Q&A',
    answerQaDescription: 'Will have the ability to answer questions in the Q&A section.',
    moderateQa: 'Moderate Q&A',
    moderateQaDescription: 'Will be allowed to delete everyone’s questions and answers in the Q&A section and download Q&A logs.',
    privateChat: 'Chat in private chat',
    privateChatDescription: 'Will have the ability to chat privately, on an individual basis with other participants.',
    presentFiles: 'Present files',
    presentFilesDescription: 'Will have the ability to open image and slideshow files and navigate through slides, making them visible to everyone in the room.',
    controlRoomComponents: 'Control room components',
    controlRoomComponentsDescription: 'Will have the ability to enable / disable room components for all participants during the session.',
    managePolls: 'Moderate polls',
    managePollsDescription: 'Will have the ability to create, manage, start and stop polls, view results locally, share results with everyone in the room and download poll results.',
    takePolls: 'Take polls',
    takePollsDescription: 'Will have the ability to submit votes in polls.',
    upvoteQa: 'Upvote Q&A',
    upvoteQaDescription: 'Will be able to upvote questions in Q&A.',
    manageContentLibrary: 'Manage content',
    manageContentLibraryDescription: 'Will be allowed to create, rename and remove files in the Content Library.',
    presentContentLibrary: 'Present content',
    presentContentLibraryDescription: 'Will be allowed to preview and open image and slideshow files in the Content Library, navigate through slides and make them visible to everyone in the room.',
    transcribeSession: 'Transcribe session',
    transcribeSessionDescription: 'Will be allowed to start and stop transcription.'
  },
  allRoles: 'All roles',
  addRolesPlaceholder: '– Add roles here –',
  selectedRoles: 'Roles',
  selectedRolesDescription: 'Select a subset of roles to be added to your new rooms by default. The order in which they’re added will be the order in which they appear in the Participants panel.',
  selectedRolesRoomDescription: 'Select a subset of roles for this room. The order in which they’re added will be the order in which they appear in the Participants panel.',
  defaultRole: 'Default role',
  defaultRoleDescription: 'Select a role to be assigned to participants who join with no token (shared link to public room) or with a token without a role.',
  unableDeleteRole: 'Unable to delete {{role}}',
  unableDeleteDefaultRole: 'This role is currently set as a default role in the default room settings.',
  ok: 'Ok',
  deleteRoleTitle: 'Delete role "{{ role }}"?',
  deleteRoleConfirmation: 'Are you sure you want to delete "{{ role }}" role?',
  deleteRoleNote: 'You won’t be able to undo this action.',
  unableDeleteRoleInLiveSessions: 'This role is currently in a live session.',
  unableDeleteRoleDefaultInRooms_zero: 'This role is currently set as a default role in room {{ room }}.',
  unableDeleteRoleDefaultInRooms_one: 'This role is currently set as a default role in room {{ room }} and one another room.',
  unableDeleteRoleDefaultInRooms_other: 'This role is currently set as a default role in room {{ room }} and {{ count }} other rooms.',
  roleUsedByRooms_zero: 'This role is being used by the {{ room }} room.',
  roleUsedByRooms_one: 'This role is being used by {{ room }} and one more room.',
  roleUsedByRooms_other: 'This role is being used by {{ room }} and {{ count }} more rooms.',
  roleConfiguredIntoRoles_zero: 'This role is configured into the {{ role }} role.',
  roleConfiguredIntoRoles_one: 'This role is configured into {{ role }} and one more role.',
  roleConfiguredIntoRoles_other: 'This role is configured into {{ role }} and {{ count }} more roles.',
  roleIsPreset: 'This role is a preset.',
  canNotUndoThisAction: 'You won’t be able to undo this action.',
  allRolesDescription: 'Choose \'All roles\' if you want the roles you create after submitting this form to be automatically considered as selected roles for this permission.',
  captionsLanguage: 'Transcription language',
  captionsLanguageDescription: 'Set the default input language to be transcribed in your rooms. Remember that each participant will have a UI to override this setting and adjust ‘Speaking language’ during the session, to match their own speech.',
  captionsLanguageRoomDescription: 'Set the default input language to be transcribed in this room. Remember that each participant will have a UI to override this setting and adjust ‘Speaking language’ during the session, to match their own speech.',
  productName: 'Digital Samba Embedded',
  productDescriptionTitle: 'Build it your way with our live video API and SDK',
  productDescription: [
    '10,000 free mins per month',
    'Meeting API and SDK support',
    'Fully mobile responsive',
    'React-based frontend',
    'No set-up fee'
  ],
  ourCustomers: 'Hundreds of developers build with Digital Samba',
  gdprCompliant: 'Digital Samba video conferencing is fully GDPR-compliant with strict E2EE for group calls and all data processed only on European infrastructure.',
  developersLogin: 'Developers log in',
  developersSignup: 'Developers sign up',
  transcriptionMinutes: 'Transcription minutes',
  transcriptionMinutesTooltip: 'Number of minutes with the Transcription feature enabled. Updated every 15 minutes.',
  accountOwnerEmail: 'Account owner email',
  createMoreRolesToUnlock: 'You need to create at least two roles to unlock this setting.',
  participationMins: 'Participation mins.',
  transcriptionMins: 'Transcription mins.',
  recordedMins: 'Recorded mins.',
  broadcastMins: 'Broadcast mins.',
  consumedMins: 'Consumed  mins.',
  desktopMins: 'Desktop mins.',
  mobileMins: 'Mobile mins.',
  tabletMins: 'Tablet mins.',
  smarttvMins: 'Smart TV mins.',
  breakoutMins: 'Breakout  mins.',
  presentationMins: 'Presentation mins.',
  screenshareBroadcastMins: 'Screenshare broadcast mins.',
  screenshareConsumedMins: 'Screenshare consumed  mins.',
  upgraded: 'Upgraded',
  downgraded: 'Downgraded',
  thisRole: 'This role',
  internal: 'Internal',
  external: 'External',
  desktopParticipants: 'Desktop participants',
  mobileParticipants: 'Mobile participants',
  tabletParticipants: 'Tablet participants',
  smarttvParticipants: 'Smart TV participants',
  fullscreen: 'Fullscreen',
  showCaptionsInRecordings: 'Show captions in recordings',
  showCaptionsInRecordingsDescription: 'Display session transcriptions as subtitles in your recordings.',
  showCaptionsInRecordingsRoomDescription: 'Display session transcriptions as subtitles in this room’s recordings.',
  showNameBadgesInRecordings: 'Show name badges in recordings',
  showNameBadgesInRecordingsDescription: 'When enabled, recordings will include participant name badges on tiles.',
  showNameBadgesInRecordingsRoomDescription: 'When enabled, recordings in this room will include participant name badges on tiles.',
  recordingLayoutMode: 'Recording layout mode',
  recordingLayoutModeDescription: 'Auto mode automatically magnifies the active speaker while tiled mode renders a basic grid with participant tiles. However, both layout modes will magnify any open content or shared screen.',
  new: 'New',
  auto: 'Auto',
  tiled: 'Tiled',
  joinAs: 'Join as',
  joinAsNote: 'Link will be valid for 3 hours',
  settings: 'Settings',
  branding: 'Branding',
  general: 'General',
  components: 'Components',
  captions: 'Captions',
  joinSettings: 'Join settings',
  tools: 'Tools',
  roomPrivacy: 'Room privacy',
  maximumNumberparticipants: 'Max number of participants',
  currentTeam: 'Current team',
  accountOwnerId: 'Account owner ID',
  createAccount: 'Create account',
  createFreeAccount: 'Create your free account',
  roleDescriptionPlaceholder: 'Add role description',
  roomDescriptionPlaceholder: 'Add room description',
  defaultRoomSettings: 'Default room settings',
  layoutModeOnJoin: 'Layout mode on join',
  layoutModeOnJoinDescription: 'Auto mode automatically magnifies any open content, shared screen or active speaker and it’s best suited for less experienced participants. Tiled mode will allow participants to drag-adjust the layout and control how they want to see individual tiles, whether default view, pinned or expanded to fullscreen.',
  resetTeamToDefault: 'Reset to factory settings',
  resetTeamToDefaultTitle: 'Are you sure you want to reset the default room settings to the factory settings?',
  resetTeamToDefaultConfirmation: 'Any unedited settings in rooms that you may have created will be reset to the factory settings. Any changes applied to roles will not be reset.',
  resetRoomToDefaultConfirmation: 'Are you sure you want to reset this room with the default values set in the <0>Default room settings</0>?',
  dashboard: 'Dashboard',
  total: 'Total',
  exportStatistics: 'Export usage statistics',
  needHelp: 'Need help?',
  welcome: 'Welcome',
  welcomeMessage: 'You can start a session in your demo room right away. Share the room link with anyone and have them join too.',
  startVideoconferencing: 'Start videoconferencing',
  doNotShowAgain: 'Don\'t show again',
  firstName: 'First name',
  lastName: 'Last name',
  recordingStarted: 'Recording started',
  recordingStopped: 'Recording stopped',
  recordingReady: 'Recording ready for download',
  disabledAfterEnablingE2ee: 'Disabled, because end-to-end encryption is enabled.',
  disabledAfterPropertyDisabling: 'Disabled, because {{ name, lowercase }} feature is not enabled.',
  propertiesDisabled_one: '{{ properties, list }} has been disabled.',
  propertiesDisabled_other: '{{properties, list}} have been disabled.',
  phoneNumber: 'Phone number',
  workPhone: 'Work phone',
  phone: 'Phone',
  company: 'Company',
  jobTitle: 'Job title',
  industry: 'Industry',
  accountOwnerPhone: 'Account owner phone',
  signupInitiated: 'Signup initiated',
  nonVerifiedAccounts: 'In-progress signups',
  exportAccounts: 'Export',
  completeSignup: 'Complete your signup',
  completeSignupDescription: 'Your subdomain <0>{{ domain }}</0> was stored successfully. To complete your free account creation and start integrating seamlessly, we kindly ask you to fill out a few additional fields. It\'ll only take a moment.',
  rename: 'Rename',
  renameRecording: 'Rename recording',
  recordingName: 'Recording name',
  oauthLoginTitle: 'Authorization Request',
  oauthLoginDescription: 'Third-party application is requesting permission to access your developer API.',
  provideAccess: 'Provide access',
  customLogo: 'Room logo',
  customLogoDescription: 'You may add a custom logo to be displayed in your rooms and recordings. Your logo should be max. 400 px wide and 80 px high. The filetype should be SVG or PNG, and file size should not be more than 1 MB. Be sure to upload a logo in a colour that will contrast adequately with your background colour selection. You’ll need to toggle on ‘Show logo in room’ and ‘Show logo in recordings’ to effectively display it in rooms and recordings.',
  customLogoDescriptionFreePlan: '<0>Upgrade your account</0> to unlock adding a custom logo to be displayed in your rooms and recordings.',
  customLogoRoomDescription: 'You may add a custom logo to be displayed in this room and its recordings. Your logo should be max. 400 px wide and 80 px high. The filetype should be SVG or PNG, and file size should not be more than 1 MB. Be sure to upload a logo in a colour that will contrast adequately with your background colour selection. You’ll need to toggle on ‘Show logo in room’ and ‘Show logo in recordings’ to effectively display it in rooms and recordings.',
  customLogoRoomDescriptionFreePlan: '<0>Upgrade your account</0> to unlock adding a custom logo to be displayed in this room and its recordings.',
  applicationLogo: 'Application logo',
  applicationLogoDescription: 'You may add a custom logo to be displayed in the application screens such as the login and join screens. Your logo should be max. 400 px wide and 80 px high. The filetype should be SVG or PNG, and file size should not be more than 1 MB. Be sure to upload a logo in a colour that will contrast adequately with your ‘appearance’ setting selection.',
  applicationLogoDescriptionFreePlan: '<0>Upgrade your account</0> to unlock adding a custom logo to be displayed in application screens such as the login and join screens.',
  applicationLogoRoomDescription: 'You may add a custom logo to be displayed in the application screens such as the login and join screens. Your logo should be max. 400 px wide and 80 px high. The filetype should be SVG or PNG, and file size should not be more than 1 MB. Be sure to upload a logo in a colour that will contrast adequately with your ‘appearance’ setting selection.',
  applicationLogoRoomDescriptionFreePlan: '<0>Upgrade your account</0> to unlock adding a custom logo to be displayed in application screens such as the login and join screens.',
  uploadFile: 'Upload file',
  inviteParticipants: '‘Invite people’ UI',
  inviteParticipantsDescription: 'When enabled, users allowed to ‘Invite participants’ will see an ‘Invite people’ button in the participants panel. Also, when alone in the room they will see a notification suggesting them to invite other participants.',
  consentMessage: 'Consent message',
  consentMessageDescription: 'When enabled, participants will be reminded that by joining, they consent to the processing of their personal data in accordance with your organisation’s Privacy Policy. They can choose to hide this message in the future. You may customise the URL of Privacy Policy to point to your organisations documentation. Choose ‘Generic message’ if you need it localised. Choose ‘Custom message’ if you need to add you own but don’t mind it being monolingual.',
  genericMessage: 'Generic message',
  customMessage: 'Custom message',
  consentMessageDefault: 'By joining, you consent to the processing of your personal data in accordance with our [link {{- url }}]Privacy Policy[/link].',
  checkboxMessage: 'Checkbox message',
  checkboxMessageDefault: 'Don’t show this again.',
  reset: 'Reset',
  maxConcurrentParticipants: 'Max concurrent participants',
  maxConcurrentParticipantsTooltip: 'Simultaneous active participants peak',
  maxDailyConcurrentParticipantsTooltip: 'Simultaneous active participants peak registered by day',
  maxActiveRooms: 'Max concurrent rooms',
  maxConcurrentSessions: 'Max concurrent sessions',
  maxConcurrentSessionsTooltip: 'Simultaneous active rooms peak',
  maxDailyConcurrentSessionsTooltip: 'Simultaneous active rooms peak registered by day',
  chatPostsTooltip: 'Total posted public and private chats',
  backToStatsPage: 'Back to the stats page',
  backToHomePage: 'Back to the home page',
  deleteAccount: 'Delete account',
  deleteAccountDescription: 'Your account will be deleted and you will be removed from the <0>{{team}}</0> team. However your recordings will not be removed, as they belong to your team.',
  deleteTeamDescription: 'Your team <0>{{team}}</0> including your own and other team members\' accounts as well as any recordings will be deleted.',
  deleteTeam: 'Delete account and team',
  deleteAccountTitle: 'Delete account',
  deleteTeamTitle: 'Delete account and team',
  deleteAccountConfirmation: 'Your account will be deleted and you will be removed from the {{team}} team. You cannot revert this action.',
  deleteTeamConfirmation: 'Your team {{team}} including your own and other team members\' accounts as well as any recordings will be deleted. You cannot revert this action.',
  cannotDeleteAccount: 'Account cannot be deleted',
  cannotDeleteAccountDescription: 'To delete your account you must first downgrade it to the Free plan. Open the ‘My Account’ section on the top-right menu, click on ‘Manage account’: The ‘Manage Subscription’ window will pop up; Click to open your subscription and then click on ‘Cancel Subscription’ to downgrade it to the Free plan.',
  anonymous: 'Anonymous',
  activeRoles: 'Active roles',
  customRolesCreated: 'Custom roles created',
  e2eeMins: 'E2EE mins.',
  unlimited: 'Unlimited',
  lockRoom: 'Lock room',
  lockRoomDescription: 'In locked rooms, participants need acceptance to join the session. In locked rooms with multiple roles, participants who have the ‘control room entry’ permission can provide acceptance. Additionally, participants with permission to ‘control room entry’ or ‘start session’ can directly join existing sessions in rooms set with multiple roles, without explicit acceptance.',
  lockRoomDescriptionSingleRole: 'In locked rooms, participants must seek acceptance to join the session. Single-role rooms cannot be locked on join, but existing participants have the option to lock them during the session and the authority to grant acceptance to waiting participants.',
  customCname: 'Custom domain',
  customCnameDescription: 'Point digital samba to your custom domain. First, configure your custom DNS CNAME record to point {{team}} to your custom domain, then enter your new custom domain.',
  endSessionTitle: 'End session?',
  endSessionConfirmation: 'Are you sure you want to end this session? All participants will be removed from the session.',
  endRoomSessionConfirmation: 'Are you sure you want to end live session of this room? All participants will be removed from the session.',
  impersonate: 'Impersonate team',
  impersonateTitle: 'You’re now impersonating the {{team}} team.',
  impersonateExit: 'Exit impersonation',
  impersonateDescription: 'Be mindful, don’t mess with their settings unless you have a good reason to.',
  allPlans: 'All plans',
  privacyPolicy: 'Privacy Policy',
  teamsCount_one: '{{ count }} team',
  teamsCount: '{{ count }} teams',
  teamDomains: 'Team domains',
  whiteboard: 'Whiteboard',
  whiteboardDescription: 'When enabled, the Whiteboard component will be added to the room and participants with the ‘edit whiteboard’ permission will be able to open it and edit content. Whiteboard is not compatible with E2EE.',
  htmlTitle: 'HTML title',
  htmlTitleDescription: 'You may add a custom title to be displayed on browser tabs, when rooms are not embedded in an iframe.',
  htmlTitleRoomDescription: 'You may add a custom title to be displayed on the browser tab, which will be visible as long as the room is not embedded in an iframe.',
  state: 'State',
  emailUnverified: 'Email unverified',
  signupIncomplete: 'Signup incomplete',
  userManagement: 'Manage DS users',
  adminRole: 'DS-Superadmin',
  salesRole: 'DS-Sales',
  devRole: 'DS-Member',
  setUserRole: 'Set role',
  deleteUser: 'Delete user',
  deleteUserTitle: 'Delete user?',
  deleteUserConfirmation: '{{ user }} will no longer have access to the Admin section.',
  addUser: 'Add user',
  user: 'User',
  selectUser: 'Select a user',
  whiteboardMins: 'Whiteboard mins.',
  whiteboardMinutesTooltip: 'Number of minutes with the whiteboard panel open',
  recodringsArchive: 'Archived recordings',
  recodringsArchiveDescription: 'Archived recordings are automatically deleted after 30 days. You will not be charged for storage cost during this period.',
  deleteRecordingTitle: 'Permanently delete recording?',
  deleteRecordingConfirmation: '"{{ recording }}" will be deleted. This action cannot be reverted.',
  deleteRecording: 'Delete recording',
  backToRecordings: 'Back to recordings',
  disabledInSingleRoleRooms: 'Disabled in single-role rooms',
  roomPrivacy_public: 'Public room',
  roomPrivacy_private: 'Private room',
  planName: '{{ plan }} plan',
  yourPlan: 'Your plan',
  manageYourAccount: 'Manage your account',
  clone: 'Clone',
  sessionStarted: 'Session started',
  sessionEnded: 'Session ended',
  live: 'Live',
  ended: 'Ended',
  endingSoon: 'Ending soon',
  onlineDemo: 'Online demo',
  search: 'Search',
  all: 'All',
  favicon: 'Favicon',
  faviconDescription: 'You may add a custom favicon to be displayed on browser tabs, when rooms are not embedded in an iframe. This feature comes with partial browser support, you may want to read our <0>partial browser considerations for the Favicon feature</0>.',
  faviconDescriptionFreePlan: 'You may add a custom favicon to be displayed on browser tabs, when rooms are not embedded in an iframe.',
  show: 'Show',
  country: 'Country',
  maximumBroadcasters: 'Maximum broadcasters',
  maximumBroadcastersDescription: 'Number of maximum allowed concurrent broadcasters per room. It will be limited by the maximum number of participants set above. If more than 100 maximum participants, then maximum broadcasters cannot be greater than 15.',
  unarchive: 'Unarchive',
  applicationError: 'Error',
  applicationErrorDescription: 'Something went wrong...',
  assignRoleTitle: 'New role created',
  assignRoleTitleDescription: 'In order to use it, you must add the "{{ role }}" role to new or existing rooms. Please also review existing role permissions and consider adding the "{{ role }}" role to role-sensitive permissions.',
  dateStart: 'Date start',
  dateEnd: 'Date end',
  maxConcurrentSessionParticipants: 'Max participants per session',
  maxConcurrentSessionParticipantsTooltip: 'Simultaneous active participants per session peak registered by day',
  maxConcurrentParticipantsPerSession: 'Max concurrent participants per session',
  recordingConsentMessage: 'Recording consent message',
  recordingConsentMessageDescription: 'When enabled, participants initiating a recording will be reminded that all participants\' consent, including those who join late, can be a legal requirement.',
  breakoutRooms: 'Breakout rooms',
  enableBreakoutRooms: 'Enable breakout rooms',
  enableBreakoutRoomsDescription: 'Allow moderators to split meeting participants into separate, smaller rooms. Note End-to-end encryption is incompatible with this feature. Note Transcription is not supported in breakout rooms.',
  preCreateBreakoutRooms: 'Pre-create breakout rooms (optional)',
  preCreateBreakoutRoomsDescription: 'Set up your room with up to 500 breakout rooms and give them custom names. Remember that moderators (participants with the ‘manage breakouts’ permission) will be able to also create rooms during the session.',
  addRoom: 'Add room',
  createRooms: 'Create rooms',
  clearRooms: 'Clear all rooms',
  breakoutRoomName: 'Room {{ number }}',
  breakoutMinutes: 'Breakout minutes',
  tokens: 'Tokens',
  tokensDescription: 'You can create <0>tokens</0> in the below playground without writing a single line of code. Useful for quick experiments.',
  properties: 'Properties',
  yourToken: 'Your token',
  copyToken: 'Copy token',
  copyUrl: 'Copy URL',
  tdDescription: 'This is the team id of {{ domain }} ',
  rdDescription: 'Enter the name (friendly url) or UUID of the room',
  udDescription: 'External user identifier (optional)',
  uDescription: 'Username (optional)',
  initialsDescription: 'Custom initials for user tiles (optional)',
  roleIdDescription: 'Role id (optional)',
  breakoutIdDescription: 'Breakout room id (optional)',
  avatarDescription: 'The url of the user’s avatar image (optional)',
  nbfDescription: 'Not before - number of seconds since the epoch (optional)',
  expDescription: 'Expiration - number of seconds since the epoch (optional)',
  source: 'Source',
  roomSource: 'Room source',
  emailPlaceholder: 'name@company.com',
  copyBreakoutId: 'Copy breakout ID',
  passwordDescription: 'Required: Password with 8+ characters, upper & lower case, and a number.',
  suspicious: 'Suspicious',
  suspiciousMaybeBots: 'Suspicious (maybe bots)',
  yes: 'Yes',
  no: 'No',
  deleteRooms: 'Delete rooms',
  deleteRoomsTitle: 'Permanently delete rooms?',
  deleteRoomsConfirmation: 'The selected rooms will be deleted. This action cannot be reverted.',
  allSources: 'All sources',
  api: 'API',
  googleCalendar: 'Google calendar',
  wordpress: 'Wordpress',
  qa: 'Q&A',
  enableQa: 'Enable Q&A',
  enableQaDescription: 'Allow participants to post, answer, and moderate questions. Answering and moderating require permission.',
  upvoteQa: 'Upvoting',
  upvoteQaDescription: 'Allow participants with the ’Upvote Q&A’ permission to vote for questions, enabling moderators to prioritise answers based on interest.',
  chat: 'Chat',
  enablePublicChat: 'Public chat',
  enablePublicChatDescription: 'Allow participants to post and view in the public chat.',
  enablePrivateChat: 'Private 1:1 chats',
  enablePrivateChatDescription: 'Enable posting and viewing in one-to-one chats with specific participants.',
  enablePrivateGroupChat: 'Private group chat',
  enablePrivateGroupChatDescription: 'Enable posting and viewing in private group chats.',
  privateGroupChatName: 'Private group display name',
  privateGroupChatNameDescription: 'Customise your private group chat with a name of your choice to be displayed.',
  privateGroupChatRoles: 'Private group roles',
  privateGroupChatRolesDescription: 'Add the roles you want participating in the private group chat.',
  freePlan: {
    name: 'Free',
    description: 'Explore Digital Samba and start integrating our video API & SDK right away.',
    restinctions: [
      '25,000 participation minutes',
      'Logo branding',
      'AI captions'
    ],
    options: [
      '10,000 participation minutes',
      '100 concurrent participants',
      '100 concurrent participants per session',
      'Unlimited concurrent sessions',
      'Cloud recordings',
      'Whiteboard',
      'Colour branding',
      'Group chat',
      'Screen share',
      'Hand raise',
      'Waiting room',
      'E2E-encryption',
      'Mobile compatibility',
      'HD quality'
    ]
  },
  upgradeTitle: 'Select the Digital Samba plan that aligns with your business requirements, ensuring a superior video conferencing experience.',
  contactSalesTeam: 'If you find that you need more participant minutes or are keen on volume discount pricing, <0>contact our friendly and knowledgeable team</0> who are always at hand to offer further information and support.',
  yourCurrentPlan: 'Your current plan',
  upgradeToPlan: 'Upgrade to {{ plan }} plan',
  downgradeToPlan: 'Downgrade to {{ plan }} plan',
  plans: {
    create: {
      price: '€99',
      large: {
        name: 'Create',
        description: 'An ideal choice for individuals looking to integrate video call functionality into event platforms or webinar software solutions.',
        options: [
          'All Free plan features',
          '25,000 participation minutes',
          '100 concurrent participants',
          '100 concurrent participants per session',
          '5 concurrent sessions',
          'Logo branding',
          'AI captions'
        ]
      },
      small: {
        name: 'Create S',
        description: 'Best suited for hosting numerous simultaneous one-on-one calls within an application.',
        options: [
          'All Free plan features',
          '25,000 participation minutes',
          '40 concurrent participants',
          '2 concurrent participants per session',
          '20 concurrent sessions',
          'Logo branding',
          'AI captions'
        ]
      }
    },
    launch: {
      price: '€372',
      large: {
        name: 'Launch',
        description: 'Ideal for large-scale video conferences, accommodating up to 200 participants and 100 video broadcasters.',
        options: [
          'All Create plan features',
          '100,000 participation minutes',
          '200 concurrent participants',
          '200 concurrent participants per session',
          '10 concurrent sessions',
          'CNAME / White labelling'
        ]
      },
      small: {
        name: 'Launch S',
        description: 'Designed for conducting multiple small video calls, with each session hosting up to three participants.',
        options: [
          'All Create S plan features',
          '100,000 participation minutes',
          '300 concurrent participants',
          '3 concurrent participants per session',
          '100 concurrent sessions',
          'CNAME / White labelling'
        ]
      }
    },
    grow: {
      price: '€1,700',
      large: {
        name: 'Grow',
        description: 'Optimal for orchestrating multiple extensive video conferences, supporting up to 500 attendees.',
        options: [
          'All Launch plan features',
          '500,000 participation minutes',
          '500 concurrent participants',
          '500 concurrent participants per session',
          '25 concurrent sessions',
          'Private Slack channel / Premium support',
          'Input into roadmap'
        ]
      },
      small: {
        name: 'Grow S',
        description: 'A prime choice for facilitating hundreds of smaller video conferences simultaneously, each with a maximum of four participants.',
        options: [
          'All Launch S plan features',
          '500,000 participation minutes',
          '800 concurrent participants',
          '4 concurrent participants per session',
          '200 concurrent sessions',
          'Private Slack channel / Premium support',
          'Input into roadmap'
        ]
      }
    },
    scale: {
      price: '€3,000',
      large: {
        name: 'Scale',
        description: 'Geared towards large-scale video calls, this plan supports events with up to 2000 attendees.',
        options: [
          'All Grow plan features',
          '1,000,000 participation minutes',
          '2,000 concurrent participants',
          '2,000 concurrent participants per session',
          '100 concurrent sessions',
          'Dedicated account manager'
        ]
      },
      small: {
        name: 'Scale S',
        description: 'Excellent for substantial projects, capable of hosting up to 300 small meetings concurrently.',
        options: [
          'All Grow S plan features',
          '1,000,000 participation minutes',
          '1,200 concurrent participants',
          '4 concurrent participants per session',
          '300 concurrent sessions',
          'Dedicated account manager'
        ]
      }
    }
  },
  next: 'Next',
  surverFinishedTitle: 'Thank you!',
  surverFinishedDescription: [
    'We appreciate your answers to this survey.',
    'This will help us make Digital Samba better.'
  ],
  satisfactionSurveys: 'Satisfaction Surveys',
  date: 'Date',
  score: 'Score',
  reason: 'Reason',
  filterByEmail: 'Filter by email',
  hideTilesInRecordings: 'Hide tiles in recordings',
  hideTilesInRecordingsDescription: 'When enabled, recordings will not capture participants’ video streams. Participants’ audio, screenshare and other type of content will still be recorded.',
  exportQaAndChatDesription: 'Select the dataset and format you wish to export for this {{ type }}.',
  export: 'Export',
  selectFormat: 'Select format',
  selectDataset: 'Select dataset',
  exportChat: 'Public chat log',
  exportQuestions: 'Q&A log',
  exportPolls: 'Polls log',
  exportTranscripts: 'Session transcript',
  exportSummary: 'Session summary',
  exportSummaryInProgress: 'Session summary - in progress',
  publicChatPosts: 'Public chat posts',
  privateChatPosts: 'Private 1:1 chat posts',
  privateGroupChatPosts: 'Private group chat posts',
  chatPosts: 'Chat posts',
  questions: 'Questions',
  answers: 'Answers',
  meetNow: 'Meet now',
  filesPanel: 'Files panel',
  filesPanelDescription: 'When enabled, participants will have access to the Files panel, as long as there are external files configured through the API.',
  linkCopied: 'Link copied',
  copied: 'Copied',
  sessionName: 'Session name',
  startSession: 'Start session',
  unknown: 'Unknown',
  estimatedMonthlyParticipationMins: 'Estimated monthly participation minutes',
  estimatedMonthlyParticipationMinsTooltip: 'How many conferencing minutes will your application consume after integration?',
  recordingsInRoom: 'Recordings - {{ room }}',
  transferOwnershipTitle: 'Promote {{ email }} and demote myself',
  transferOwnershipConfirmation: 'Are you sure you want to transfer ownership to {{ email }}? You will be demoted to Team Admin and you won’t have the option to revoke this operation.',
  transferOwnershipToTeamOwnerTitle: 'Ownership transfer unavailable',
  transferOwnershipToTeamOwner: 'You cannot transfer team ownership to {{ email }} because they are already a Team owner in another team.',
  e2eeDisabled: 'Disabled after setting Maximum participants to more than {{ participants }}',
  yourRoomUrl: 'Your room URL',
  ready: 'Ready',
  allStatuses: 'All statuses',
  transcription: 'Transcription',
  enableTranscription: 'Transcription',
  enableTranscriptionDescription: 'When enabled, the speech of participants in your rooms will be able to be transcribed into text. Note that either you need to enable below auto-start toggle or a user with Transcribe session permission needs to start the transcription process from inside the room. Session transcripts and summaries will be accessible from the dashboard and can be also retrieved from the API. Note End-to-end encryption is incompatible with this feature. Note Transcription is not supported in breakout rooms.',
  enableTranscriptionRoomDescription: 'When enabled, the speech of participants in this room will be able to be transcribed into text. Note that either you need to enable below auto-start toggle or a user with Transcribe session permission needs to start the transcription process from inside the room. Session transcripts and summaries will be accessible from the dashboard and can be also retrieved from the API. Note End-to-end encryption is incompatible with this feature. Note Transcription is not supported in breakout rooms.',
  addLanguagesPlaceholder: '- Select languages -',
  roomLanguages: 'Languages',
  roomLanguagesDescription: 'Select a subset of languages for participants to be able to choose from.',
  systemCheck: 'System check',
  nextSteps: 'Important next steps',
  nextStepsDescription: 'Even if your test was successful, please take note of the following details, and then head over to our network configuration page, found <0>here</0>.',
  webServer: 'Web server',
  signalingServer: 'Signaling server',
  mediaServer: 'Media server',
  mediaServerApi: 'Media server API',
  mediaTraffic: 'Media traffic',
  mediaTrafficOptions: [
    'OPTION 1 (Best performance)',
    'OPTION 2 (Via a turn/relay server)'
  ],
  httpTrafficPort: 'Allow HTTPs traffic on port <0>{{ port }}</0>',
  wssTrafficPort: 'Secure websockets/wss traffic on port <0>{{ port }}</0>',
  mediaTrafficPort: 'HTTPs traffic on port <0>{{ port }}</0>',
  rtpTrafficPort: 'Allow RTP traffic TCP/UDP port',
  turnServer: 'Turn server',
  polls: 'Polls',
  pollsDescription: 'When enabled, participants with the ‘manage polls’ permission will have the ability to create and launch polls; and those with the ‘take polls’ permission will have the ability to submit their votes. In single-role rooms, all participants will be able to take polls, even if not explicitly set with the ‘take polls’ permission. Note End-to-end encryption is incompatible with Polls.',
  endedPolls: 'Polls',
  endedPollsTooltip: 'Number of ended polls.',
  systemCheckWelcome: 'Welcome to System check',
  systemCheckWelcomeMessage: 'On this page you can check if your system is ready to connect to the conference.',
  systemCheckWelcomeStart: 'Start System check',
  roomNotDefined: 'Room needs to be defined',
  breakoutsNotFound: 'Breakouts need to be precreated',
  downgradeSubscriptionTitle: 'Are you sure you want to downgrade to the {{ plan }} plan?',
  downgradeSubscriptionConfirmation: 'Your plan will be automatically downgraded to the {{ plan }} plan at the end of your subscription on {{ endDate }}, but you can enjoy the {{ currentPlan }} until then.',
  downgradeSuccessfulTitle: 'Downgrade request successful',
  downgradeSuccessfulDescription: 'Your plan will be automatically downgraded to the {{ plan }} plan at the end of your subscription on {{ endDate }}.',
  networkSettings: 'Network firewall or proxy server settings',
  networkSettingsNote: 'NOTE: All signalling and mediaserver endpoints can be whitelisted via allowing wildcard domain *.wbcnf.net if the client firewall allows such a configuration',
  ip: 'IP',
  ips: 'IPs',
  uri: 'URI',
  summaries: 'Summaries',
  recordingAutostart: 'Recording auto-start',
  recordingAutostartDescription: 'Recording will automatically start when the first participant joins. Breakout sessions will not be automatically recorded.',
  broadcasterTileVisibility: 'Broadcaster tile visibility',
  broadcasterTileVisibilityDescription: 'Set the visibility of tiles containing broadcaster’s initials depending on cam and mic state. This setting applies only to users with broadcast permission. This setting will affect the room itself and any recordings within it.',
  broadcasterTileVisibilityAll: 'Show all broadcaster tiles',
  broadcasterTileVisibilityCamMic: 'Only show tiles of broadcasters with active cam, mic or both',
  broadcasterTileVisibilityCam: 'Only show tiles of broadcasters with active cam',
  broadcasterTileVisibilityMaxParticipants: 'Set automatically after setting maximum participants to more than 100',
  broadcasterTileVisibilityMaxBroadcasters: 'Set automatically after setting maximum broadcasters to more than 15',
  createPoll: 'Create poll',
  preCreatedPolls: 'Pre-create polls (optional)',
  preCreatedPollsDescription: 'You can create polls to use them during the meeting. You can also create polls during the session.',
  singleChoice: 'Single choice',
  multipleChoice: 'Multiple choice',
  question: 'Question',
  anonymousPoll: 'Anonymous poll',
  addOption: 'Add choice',
  option: 'Choice {{ number }}',
  create: 'Create',
  deletePollTitle: 'Delete poll?',
  deletePollConfirmation: 'Are you sure you want to delete this poll?',
  deleteLaunchedPollTitle: 'Are you sure you want to delete this poll?',
  deleteLaunchedPollConfirmation: 'Deleting this poll will also delete any associated results. If you want to store the results, navigate to this session in the Sessions page and export the polls log.',
  deletePoll: 'Delete poll',
  deleteActivePollTitle: 'You can\'t delete this poll now',
  deleteActivePollConfirmation: 'You can\'t delete a poll in a live session. Please try again when the session has ended.',
  editLaunchedPollTitle: 'Edit poll',
  editLaunchedPollConfirmation: 'You cannot edit a poll which was launched before.',
  secretCode: 'Secret Code',
  qrDescription: 'Please scan the QR code on your mobile device to get the Secret code. You may need to install a Google Authenticator compatible app on your device.',
  twoFactorAuthentication: 'Two factor authentication',
  secretCodeDescription: 'Please enter the secret code provided by your 2FA app below.',
  disabledAfterDisablingTranscription: 'Disabled after disabling Transcription.',
  scheduledDowngrade: 'Scheduled downgrade to {{ plan }} on {{ date }}',
  hdVideoOnJoin: 'Activate HD video on join',
  hdVideoOnJoinDescription: 'Participants will stream in better quality. This setting requires more uplink bandwidth on the participants\' side.',
  sessionLength: 'Session length',
  sessionLengthDescription: 'Enter a value in minutes after which sessions should automatically end. Leave it empty if you would like sessions to remain open until someone expressly ends them or until the last participant has left.',
  sessionLengthRoomDescription: 'Enter a value in minutes after which all sessions in this room should automatically end. Leave it empty if you would like sessions to remain open until someone expressly ends them or until the last participant has left.',
  captionsMins: 'Captions mins',
  rememberMyComputer: 'Remember my computer',
  libraries: 'Libraries',
  libraryName: 'Library name',
  libraryId: 'Library ID',
  deleteLibraryTitle: 'Are you sure you want to delete this library?',
  deleteLibraryConfirmation: 'The library "{{ name }}" will permanently be removed from your team and will no longer be displayed in any rooms it was wired to.',
  deleteLibrary: 'Delete library',
  deleteFolderTitle: 'Are you sure you want to delete this folder?',
  deleteFolderConfirmation: 'The folder "{{ name }}" will permanently be removed from your library.',
  deleteFolder: 'Delete folder',
  createLibrary: 'Create library',
  createNewLibrary: 'Create new library',
  createLibraryTitle: 'Create new library',
  renameLibrary: 'Rename library',
  libraryNameDescription: '',
  libraryNamePlaceholder: 'Enter a library name',
  createFolder: 'Create folder',
  libraryIsEmpty: 'This library is empty.',
  folderIsEmpty: 'This folder is empty.',
  createFolderTitle: 'Create new folder',
  folderName: 'Folder name',
  folderNamePlaceholder: 'Enter a folder name',
  addFile: 'Add file',
  renameFolder: 'Rename folder',
  createFileTitle: 'Upload files',
  fileType: 'Type',
  fileName: 'File name',
  renameFile: 'Rename file',
  fileNamePlaceholder: 'Enter a file name',
  deleteFileTitle: 'Are you sure you want to delete this file?',
  deleteFileConfirmation: 'The file "{{ name }}" will permanently be removed from your library.',
  deleteFile: 'Delete file',
  selectFile: 'Select file',
  yourFile: 'Your file',
  fileTypes: {
    image: 'Upload image',
    video: 'Upload  video',
    pdf: 'Upload PDF',
    link: 'Add link'
  },
  contentLibrary: 'Content Library',
  contentLibraryDescription: 'When enabled, the Content Library component will be added to the room, allowing participants with permission to present files to open images and slideshows, navigate through slides, and make them visible to everyone in the room.',
  selectLibrary: 'Select library',
  selectLibraryDescription: 'Wire a library to this room.',
  transcriptionAutoStart: 'Transcription auto-start',
  transcriptionAutoStartDescription: 'Transcription will automatically start when the first participant joins. Breakout sessions will not be automatically transcribed.',
  roomExpiresAt: 'Expires At (UTC)',
  roomExpiresAtDescription: 'Room will be automatically deleted after the specified expiration time is reached. Nobody will be able to join the room after that expiration time.',
  confidentialitySettings: 'Make recordings, transcripts and files confidential',
  confidentialitySettingsDescription: 'Only the selected team roles will have access to opening  recordings, downloading transcripts and files.',
  teamOwner: 'Team owner',
  teamAdmin: 'Team admin',
  teamRegularMember: 'Team regular member',
  deleteAllRecordings: 'Delete all',
  deleteRecordings: 'Delete recordings',
  cleanupRecordingArchiveTitle: 'Permanently delete all archived recordings?',
  cleanupRecordingArchiveConfirmation: 'All archived recordings will be deleted. This action cannot be reverted.',
  linkFile: 'Link file',
  pipEnabled: 'Picture-in-picture',
  pipEnabledDescription: 'When enabled, participants will be able to extract the video and audio tiles into a new window, so they can read or watch another application while they still monitor and look at the conference. Very useful in scenarios when you are screensharing and still want to see the other participants. Picture-in-picture is available only under Chrome desktop browser and not yet available in an embedded (iframe) scenario.',
  pipMins: 'PiP mins'
};
